@mixin center-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@mixin align-center {
  display: flex;
  align-items: center;
}

@mixin custom-scroll {
  transition: all 0.5s ease;
  &::-webkit-scrollbar {
    transition: all 0.5s ease;
    width: 7px;
    transition: all 0.5s ease;
    background-color: #ddd;
  }
  &::-webkit-scrollbar-thumb {
    transition: all 0.5s ease;
    border-radius: 8px;
    background: #bbbaba;
  }
  ::-webkit-scrollbar-track {
    border-radius: 7px;
    transition: all 0.5s ease;
    background: #eaeaea;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      background: #eaeaea;
    }
    &::-webkit-scrollbar {
      background-color: #ddd;
      transition: all 0.5s ease;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbbaba;
    }
  }
}
