.pipeline-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: calc(100vh - 212px);
  .pipeline-column {
    min-height: 28px;
    padding: 0px;
    border-radius: 2px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    margin: 20px 10px 10px;
    max-width: 400px;
    min-width: 400px;
  }
  .pipeline-col-header {
    border-bottom: 1px solid #f8f9fa;
    background: #303d52;
    padding: 12px;
    border-radius: 2px 2px 0 0;
    color: #fff;
    .btn {
      flex: 0 0 75px;
      max-width: 75px;
    }
  }
  .pipeline-col-footer {
    border-top: 1px solid #f8f9fa;
    border-radius: 0 0 2px 2px;
    padding: 0.75rem 1.25rem;
    font-size: 12px;
  }
  .pipeline-title {
    font-size: 16px;
    margin: 0 0 0px;
    font-weight: 600;
  }
  .pipeline-body {
    padding: 15px 12px;
    background-color: #ecf0f3;
    border-radius: 2px;
    height: calc(100% - 100px);

    overflow-y: auto;
  }
  .pipeline-item {
    padding: 8px;
    border-radius: 2px;
    background-color: #fff;
    margin-bottom: 8px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    * {
      font-size: 14px;
    }
    .opportunity-title {
      .feather-icon {
        width: 14px;
        height: 14px;
      }
    }
    .pipeline-item-body {
      span,
      strong {
        font-size: 12px;
      }
      gap: 5px 12px;
    }
  }
}

.pipeline-list {
  .pipeline-list-item {
    border: 0px;
    border-bottom: 1px solid #dfdfdf;
    .pipline-head {
      flex: 1;
      font-size: 14px;
      margin: 0px;
      font-weight: 600;
    }
  }
}
.card .card-title {
  font-weight: 600;
  font-size: 16px;
  color: #181c32;
  line-break: anywhere;
  margin-bottom: 0px;
}
