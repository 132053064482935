// Here you can add other styles

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: $dark;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .flex-1 {
    flex: 1;
  }

  .font-weight-medium {
    font-weight: 500;
  }

  .font-12 {
    font-size: 12px;
  }
}

.admin-login-wrap {
  background-color: #ebf3fa;
  padding: 80px 16px;
}

.admin-login-card {
  padding: 32px;
  background-color: rgb(255, 255, 255);
  color: rgb(23, 43, 77);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px;
  background-image: none;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  border: none;
}

.form-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 16px;
  text-shadow: none;
  overflow: initial;
}

.form-content {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.form-style {
  .form-group {
    margin-bottom: 1.25rem;
  }

  label {
    color: rgba(0, 0, 0, 0.89);
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    display: block;
  }

  input {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    background-color: #f2f9ff;
    color: #5b616a;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 4px;
    height: 54px;
    border-color: #cedced;

    &:focus {
      background-color: #f2f9ff;
      border-color: #0071bb;
      box-shadow: none;
      outline: 2px solid #0071bb;
    }
  }

  .custom-button {
    font-size: 1rem;
    line-height: 0.9;
    color: white;
    background-color: #0071bb;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    border-radius: 0.25rem;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    margin-right: 0.5rem;
    padding: 0.75rem 1.25rem;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
}

.saved-cards-wrap {
  .saved-card {
    padding: 32px 24px;
    border: 1px dashed #ddd;
    border-radius: 8px;
  }
}

.button-sm {
  .btn-sm {
    padding: 0.25rem 0.5rem;
    line-height: 1;

    &:last-child {
      padding: 0;
    }
  }
}

.paragraph {
  padding-top: 18px;
}

.guest-modal {
  width: auto;
  margin: 76px 108px 4px 108px;
}

.guest-modal-body {
  background-color: #4397d3;
  color: #fff;
}

.subscribe-modal {
  .modal-header {
    display: none;
  }

  .modal-content {
    border: 0px;
  }

  .modal-body {
    background: $theme-color;
    color: $white;
  }
}

.error-div {
  text-align: center;

  h1 {
    font-size: 100px;
    color: #000;
    font-weight: bold;
  }

  p {
    font-size: 20px;
  }
}

.c-sidebar~.c-wrapper .opportunity-single-wrap .opportunity-nav {
  left: 0px;
}


@media (min-width:992px){
  
  .c-sidebar-minimized.c-sidebar-lg-show~.c-wrapper .opportunity-single-wrap .opportunity-nav {
    left: 54px;
  }
  .c-sidebar-lg-show~.c-wrapper .opportunity-single-wrap .opportunity-nav {
    left: 240px;
  }
}

.cms-content-wrapper .opportunity-single-wrap .opportunity-nav {
  left: 0px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 510px;
  }

  @media (min-width: 768px) {
    max-width: 690px;
  }

  @media (min-width: 992px) {
    max-width: 930px;
  }

  @media (min-width: 1200px) {
    max-width: 1110px;
  }

  @media (min-width: 1400px) {
    max-width: 1290px;
  }
}


body .c-header .notification-dropdown .notification-item {
  display: block;
  align-items: flex-start;
  padding: 0.75rem 1rem;
  white-space: normal;
  cursor: pointer;
  border-bottom: 1px solid #e2e2e2;
  transition: all 0.3s ease-in-out;
}
.c-header .notification-dropdown .notification-item .notification-head {
  font-size: 15px;
  line-height: normal;
  margin-bottom: 4px;
  color: #000;
  white-space: nowrap;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}
.aside-filter{
  position: sticky;
  top: 80px;
}
.opportunity-title{
  .badge{
    font-size: 55%;
  }
}