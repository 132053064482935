body {

  // header css
  .c-header {
    border-bottom: 0px;
    min-height: 65px;
    background: $white;
    transition: all 0.45s ease 0s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);

    .brand-logo {
      padding: 2px 20px;
      cursor: pointer;

      img {
        width: auto;
        height: 50px;

        @media (max-width: 359px) {
          height: 44px;
        }
      }
    }

    .dropdown-menu {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      font-size: 13px;
      text-align: left;
      padding: 8px 0px;
      list-style: none;
      background-color: $white;
      border: 1px solid rgba(34, 41, 47, 0.05);
      border-radius: 4px;
      animation-name: DropDownSlide;
      animation-duration: 0.3s;
      animation-fill-mode: both;

      // &.show {
      //   &:after {
      //     content: " ";
      //     position: absolute;
      //     bottom: 100%;
      //     right: 73px;
      //     border-width: 9px;
      //     border-style: solid;
      //     border-color: $transparent $transparent $white $transparent;
      //   }
      // }
      .dropdown-divider {
        margin: 2px auto;
        width: 80%;
        border-top: 1px solid #f1f1f3;
      }

      .dropdown-header {
        color: $dark;
        font-weight: 500;
        font-size: 14px;
      }

      .dropdown-item {
        position: relative;
        padding: 10px 20px;
        color: $dark;
        font-size: 13px;
        white-space: nowrap;
        font-weight: 500;
        @include align-center;
        text-align: left;
        border: 0;
        transition: all 0.3s ease;
        border-radius: 2px;

        &:hover {
          color: $dark;
          text-decoration: none;
          color: #4397d3;
          background: rgba(67, 151, 211, 0.18);
        }

        &:active,
        &.active {
          color: $white;
          background-color: $theme-color;
        }

        .feather-icon {
          width: 18px;
          height: 18px;
          font-size: 18px;
        }
      }
    }

    .c-header-nav {
      .c-header-nav-item {
        margin: 0px 5px;

        &.show {
          .c-header-nav-link {
            color: $dark;
            background: #eceaea;
            border-radius: 4px;
          }
        }
      }

      .c-header-nav-link {
        color: $dark;
        padding: 8px 10px;

        &:hover {
          color: $dark;
          background: #eceaea;
          border-radius: 4px;
        }
      }

      .feather-icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
      }

      .header-search-bar {
        position: relative;
        display: flex;
        width: 300px;
        height: 100%;
        align-items: center;

        @media (max-width: 905px) {
          width: 263px;
        }

        .form-control {
          color: #000;
          border: none;
          height: 38px;
          padding-left: 40px;
          padding-right: 20px;
          background-color: #f3f8fb;
          box-shadow: none;
          border-radius: 30px;

          &:focus {
            box-shadow: none;
            outline: none;
            border: none;
          }
        }

        .search-icon {
          position: absolute;
          left: 13px;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          font-size: 20px;
          color: #74788d;
        }
      }
    }

    .notification-dropdown {
      .badge {
        width: 22px;
        height: 22px;
        padding: 0px;
        @include center-item;
        font-size: 10px;
        margin-top: -26px;
      }

      .nav-link {
        svg {
          width: 22px;
          height: 22px;
          font-size: 22px;
        }
      }

      .dropdown-menu {
        min-width: 350px;
        padding: 0px;
      }

      .notification-item {
        display: block;
        align-items: flex-start;
        padding: 0.75rem 1rem;
        white-space: normal;
        cursor: pointer;
        border-bottom: 1px solid #e2e2e2;
        transition: all 0.3s ease-in-out;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background: #f7f7f7;
          color: #181c32;
        }

        &.unread {
          background-color: #e8f2f9;
        }

        .notification-icon {
          height: 2rem;
          width: 2rem;
          margin-right: 12px;
          align-items: center;
          background-color: #556ee6;
          color: #fff;
          display: flex;
          font-weight: 500;
          justify-content: center;
          border-radius: 50%;

          img {
            width: 16px;
            filter: brightness(0) invert(1);
          }

          &.color-1 {
            background-color: #6ad8a0;
          }

          &.color-2 {
            background-color: #7e70d6;
          }

          &.color-3 {
            background-color: #bb184b;
          }

          &.color-4 {
            background-color: #6fd4de;
          }

          &.color-5 {
            background-color: #f0ca21;
          }
        }

        .notification-content {
          flex: 1 1;
          font-size: 12px;
          line-height: normal;
        }

        .notification-head {
          font-size: 15px;
          line-height: normal;
          margin-bottom: 4px;
          color: #000;
          white-space: nowrap;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .notification-time {
          font-size: 12px;
          display: inline-flex;
          align-items: center;
          line-height: normal;
        }
      }
    }

    .user-dropdown {
      .user-dropdown-toggle {
        @include align-center;
        font-size: 13px;
        position: relative;

        &:hover {
          color: $dark;
          background: $transparent;
          border-radius: 0%;
        }

        .c-avatar {
          border-radius: 4px;
          width: 40px;
          height: 40px;
          color: $primary;
          background: rgba(67, 151, 211, 0.18);

          .c-avatar-img {
            border-radius: 0px;
            width: 24px;
            height: 24px;
          }
        }

        .c-avatar-info {
          padding-right: 8px;
          position: relative;
          font-size: 15px;
          color: $dark;
          font-weight: 500;
          font-size: 14px;
          text-align: right;

          div {
            max-width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          @media (max-width: 575px) {
            display: none;
          }
        }

        .dropdown-arrow {
          width: 16px;
          height: 16px;
          font-size: 16px;
        }
      }
    }

    &.second-header {
      padding: 5px 30px;

      .c-header-brand {
        margin-right: 30px;
        cursor: pointer;
      }

      .c-header-nav {
        .c-header-nav-item {
          .c-header-nav-link {
            font-size: 15px;
            font-weight: 500;
            color: #000;

            &:hover {
              background: transparent;
              color: $theme-color;
            }
          }

          &.show {
            .c-header-nav-link {
              color: $theme-color;
              background: transparent;
            }
          }
        }
      }
    }
  }

  // sidebar css
  .c-sidebar {
    box-shadow: 10 0.75rem 1.5rem rgb(18, 38, 63, 0.03);
    background: #303d52;
    width: 240px;

    .c-sidebar-brand {
      color: $white;
      background: $transparent;
      border-bottom: 1px solid rgba(146, 146, 146, 0.18);
      padding: 18px 20px;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    &.c-sidebar-minimized {
      .c-sidebar-nav {
        margin: 0px;

        .c-sidebar-nav-item {
          padding: 4px 8px;

          .c-sidebar-nav-link {
            padding: 8px 10px;
            white-space: nowrap;

            &:hover {
              @media (min-width: 992px) {
                width: 256px;
              }
            }
          }

          &:hover {
            background: transparent;

            .c-sidebar-nav-link {
              color: $white;
              background-color: #212b3c;
              white-space: nowrap;

              .c-sidebar-nav-icon {
                color: $white;
              }
            }
          }
        }

        .c-sidebar-nav-icon {
          flex: 0 0 50px;
        }

        .c-sidebar-nav-dropdown {
          padding: 0px 8px;

          .c-sidebar-nav-dropdown-toggle {
            padding: 8px 9px;

            &:hover {
              @media (min-width: 992px) {
                width: 320px;
              }
            }
          }

          &:hover {
            background: $transparent;

            @media (min-width: 992px) {
              width: 338px;
            }

            .c-sidebar-nav-dropdown-toggle {
              background: $primary;
              color: $white;

              .c-sidebar-nav-icon {
                color: $white;
              }
            }
          }

          .c-sidebar-nav-item {
            padding: 4px 0px;

            .c-sidebar-nav-link {
              width: 270px;
              border-radius: 0px;
            }
          }
        }
      }
    }

    .c-sidebar-nav {
      padding-top: 20px;
      margin: 0px 8px;

      li {
        margin-top: 4px;
      }
    }

    .c-sidebar-nav-item {
      padding: 0px 0px;

      .c-sidebar-nav-link {
        @include align-center;
        font-size: 14px;
        transition: all 0.5s ease;
        padding: 10px 16px;
        cursor: pointer;
        color: #b4b7bd;
        border-radius: 4px;
        white-space: normal;
        font-weight: 500;

        .c-sidebar-nav-icon {
          width: auto;
          font-size: 20px;
          color: #b4b7bd;
          width: 22px;
          height: 22px;
          fill: none;
          stroke: currentColor;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        &:hover {
          color: $white;
          background: rgba(0, 0, 0, 0.25);

          .c-sidebar-nav-icon {
            color: $white;
          }
        }

        &.c-active {
          color: $white;
          box-shadow: 0 0 4px 1px rgba(67, 151, 211, 0.7);
          background: $primary;

          .c-sidebar-nav-icon {
            color: $white;
          }
        }

        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          // background: url("../assets/images/icons/lock.svg") #ddd center right
          //   no-repeat;
          background-position: 94% 11px;
          background-size: 18px;
          opacity: 0.8;
        }
      }
    }

    .c-sidebar-nav-dropdown {
      .c-sidebar-nav-dropdown-toggle {
        @include align-center;
        font-size: 14px;
        font-weight: 500;
        transition: all 0.5s ease;
        padding: 10px 16px;
        cursor: pointer;
        color: #b4b7bd;
        border-radius: 4px;

        .c-sidebar-nav-icon {
          width: auto;
          font-size: 20px;
          color: #b4b7bd;
          width: 22px;
          height: 22px;
          fill: none;
          stroke: currentColor;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        &:hover {
          color: $white;
          background: rgba(0, 0, 0, 0.25);

          .c-sidebar-nav-icon {
            color: $white;
          }
        }
      }

      .c-sidebar-nav-dropdown-items {
        .c-sidebar-nav-item {
          .c-sidebar-nav-link {
            .c-sidebar-nav-icon {
              font-size: 12px;
              width: 12px;
              height: 12px;
              flex: 0 0 35px;
              margin-left: -10px;
            }

            &.c-active {
              //   box-shadow: none;
              // background: $transparent;
            }
          }
        }
      }
    }
  }

  .c-main {
    padding: 15px 0px;

    .container-fluid {
      padding: 0 16px;
    }
  }

  //   breadcrumb
  .breadcrumb {
    padding: 0px;
    border-bottom: 0px;
    box-shadow: none;

    .breadcrumb-item {
      align-items: center;
      position: relative;

      &:first-child {
        padding-left: 23px;

        &:before {
          content: "";
          position: absolute;
          left: 0px;
          top: 0px;
          // background-image: url("../assets/images/icons/home-black.svg");
          background-size: 17px;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
        }

        &:hover {
          &::before {
            // background-image: url("../assets/images/icons/home.svg");
          }
        }
      }

      a {
        font-size: 13px;
        color: $dark;

        &:hover {
          text-decoration: none;
          color: $theme-color;
        }
      }

      &+.breadcrumb-item {
        &:before {
          color: $dark;
        }
      }

      &.active {
        // color: $theme-color-gray;
        color: $theme-color;
        font-size: 13px;
      }
    }
  }
}

@keyframes DropDownSlide {
  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

@media (min-width: 992px) and (min-width: 992px) {

  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper {
    margin-left: 240px;
  }
}

html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
  margin-left: -240px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-14 {
  gap: 14px;
}

.gap-16 {
  gap: 16px;
}

.gap-18 {
  gap: 18px;
}

.gap-20 {
  gap: 20px;
}

.gap-22 {
  gap: 24px;
}

.gap-24 {
  gap: 24px;
}

.gap-26 {
  gap: 26px;
}

.gap-28 {
  gap: 28px;
}

.gap-32 {
  gap: 32px;
}

.norification-container {
  min-width: 350px;
  padding: 0px;
  max-width: 400px;
  max-height: 500px;
  overflow-y: auto;
}