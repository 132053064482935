.auth-wrapper {
  overflow: hidden;
  min-width: 100%;
  // min-height: 100vh;
  .auth-content {
    padding: 20px 15px;
    //  padding: 0px;
    flex: 1 0 auto;
    background: #edeeef;
    font-size: 14px;
    // min-height: 100vh;
    width: 100%;
  }
  .logo-div {
    margin-bottom: 40px;
    img {
      height: 40px;
    }
  }

  .auth-title {
    font-size: 30px;
    color: $secondary;
    font-weight: 700;
  }
  .auth-sub-title {
    font-size: 18px;
    font-weight: 600;
  }
  .auth-card {
    max-width: 480px;
    width: 100%;
    background-color: $white;
    padding: 40px 35px;
    border-radius: 6px;
    box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
    margin: 0px auto 24px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    &.register-card {
      max-width: 700px;
    }
  }
  .auth-btn {
    @include center-item;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 6px;
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  }
  .verify-icon {
    width: 60px;
    height: 60px;
  }
}
