.opportunity-detail {
  .card {
    scroll-margin-top: 120px;
  }
}

.opportunity-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  font-size: 14px;
  .opportunity-title,
  .opportunity-title a {
    font-size: 22px;
    color: $primary;
    text-decoration: none;
  }
  .card-header {
    border: none;
  }
  .card-footer {
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
}
.opportunity-single-wrap {
  .card-head-wrap {
    .card-title {
      font-size: 26px;
      font-weight: 600;
    }
  }
  .opportunity-nav {
    border-bottom: 1px solid $primary;
    .nav-item {
      .nav-link {
        color: #3c4b64;
        font-weight: 500;
        padding: 10px 30px 10px 0px;
        &:hover {
          color: $primary;
        }
        &.disabled {
          opacity: 0.5;
        }
      }
      &.is-active {
        .nav-link {
          color: $primary;
        }
      }
    }
  }
}
.similar-opportunity {
  background-color: #f9fbfd;
}
.similar-opportunity-wrap {
  .recommended-item {
    background-color: #fff;
    border: 1px solid #edf2f9;
    border-radius: 0.5rem;
    padding: 16px 16px 16px 58px;
    position: relative;
    width: 100%;
    .smiliar-list-icon {
      position: absolute;
      left: 16px;
    }
    .smiliar-item-title {
      font-size: 16px;
      color: #265d94;
      &:not(:hover) {
        text-decoration: none;
      }
    }
  }
}
.custom-height-wrap {
  overflow: auto;
  max-height: 540px;
}

.active-filter-item {
  padding: 4px;
  background: #ddd;
  .close-icon {
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
}

.pac-container {
  background-color: #fff;
  position: absolute;
  left: 0px;
  top: 46px;
  width: 100%;
  z-index: 1000;
  border-radius: 2px;

  font-family: "Montserrat", sans-serif;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  overflow: hidden;
  .loading-div {
    padding: 4px 6px;
    font-size: 13px;
    color: #000;
    text-align: center;
  }
  .pac-item {
    cursor: pointer;
    padding: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: normal;
    text-align: left;
    border-top: 1px solid #d9d9d9;
    background-color: white;
    font-size: 13px;
    color: #000;
    &:hover {
      background-color: rgb(231 230 230);
    }
    .pac-icon {
      color: #666;
    }
  }
}
.agencyName {
  font-size: 17px;
}
.small_title {
  font-size: smaller;
}
.filter-field-wrap .filter-field-tile {
  margin-top: auto;
}
.saved-wrap {
  .btn {
    min-height: 40px;
    min-width: 40px;
    border-radius: 2px;
    padding: 8px 10px;
    margin-left: 4px;
  }
}
.rdt_Table .rdt_TableCell {
  width: max-content;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .filter-field-wrap .filter-field-tile {
    max-width: 240px;
  }
  .filter-field-wrap {
    margin-bottom: 5px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .time-text {
    margin-right: 20px;
  }
  .time-widget {
    font-size: 13px;
  }
  .iGIWIN {
    top: 0;
    left: 0;
  }
  .filter-wrap {
    margin-top: 10px;
  }
  .filter-field-wrap {
    flex-wrap: wrap;
    align-items: center;
  }
  .filter-field-wrap .filter-field-tile {
    max-width: 188px;
  }
}

/* Small devices (phones, 575px and down) */
@media only screen and (max-width: 575px) {
  .filter-field-wrap {
    flex-wrap: wrap;
    align-items: center;
    width: -webkit-fill-available;
  }
  .filter-field-wrap .filter-field-tile {
    max-width: 175px;
  }
  .opportunity-btn-wrap {
    display: inline-flex;
  }
  .opportunity-footerSlot {
    display: flex;
  }
}

/* Extra small devices (phones, 441px and down) */

@media only screen and (max-width: 441px) {
  .filter-field-wrap {
    flex-wrap: wrap;
    align-items: center;
  }
  .filter-field-wrap .filter-field-tile {
    max-width: 129px;
  }
}

// * Extra small devices (phones, 360px and down) */

@media only screen and (max-width: 360px) {
  .filter-field-wrap {
    flex-wrap: wrap;
    align-items: center;
    width: -webkit-fill-available;
  }
  .filter-field-wrap .filter-field-tile {
    max-width: 144px;
  }
  .opportunity-footerSlot {
    display: flex;
    margin-bottom: 10px;
  }
  .saved-wrap .btn {
    max-height: 40px;
    min-width: 41px;
  }
}
