.award-title{
    font-size: .95rem;
    font-weight: 600;
}
.award-content{
    font-size: 13px;
    .time-widget{
        font-weight: normal;
    }
}
.progress{
    height: 31px;
}
.progress-bar{
    font-size: 13px;
}
