.dashboard-card {
  height: calc(100% - 24px);
  .card-body {
    max-height: 450px;
    overflow-y: auto;
    @include custom-scroll;
  }
}

.mini-card {
  overflow: hidden;
  transition: all 0.25s ease;
  box-shadow: 0 -3px 31px 0 rgba(0, 0, 0, 0.05),
    0 6px 20px 0 rgba(0, 0, 0, 0.02);
  height: calc(100% - 24px);
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
  }
  .card-body {
    padding: 30px 20px 24px;
  }

  .card-text {
    font-size: 16px;
    color: $black;
    font-weight: 600;
    h5 {
      font-weight: bold;
      font-size: 20px;
      &.color-1 {
        color: #6ad8a0;
      }
      &.color-2 {
        color: #7e70d6;
      }
      &.color-3 {
        color: #bb184b;
      }
      &.color-4 {
        color: #6fd4de;
      }
      &.color-5 {
        color: #3871ff;
      }
      &.color-6 {
        color: #ffa800;
      }
    }
    h4 {
      font-size: 24px;
      font-weight: 600;
    }
  }
  .card-icon {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ddd;
    border-radius: 3px;
    background: $theme-color;
    box-shadow: 0 0px 24px rgba(12, 12, 107, 0.1);
    &.color-1 {
      background: rgba(105, 216, 159, 0.25);
      color: #6ad8a0;
    }
    &.color-2 {
      background: rgba(126, 113, 214, 0.25);
      color: #7e70d6;
    }
    &.color-3 {
      background: rgba(187, 24, 76, 0.25);
      color: #bb184b;
    }
    &.color-4 {
      background: rgba(111, 213, 222, 0.25);
      color: #6fd4de;
    }
    &.color-5 {
      background: rgba(56, 112, 255, 0.25);
      color: #3871ff;
    }
    &.color-6 {
      background: rgba(255, 170, 0, 0.25);
      color: #ffa800;
    }
  }
}
