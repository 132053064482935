@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// card css
.card {
  border: 0;
  box-shadow: 0 0 20px 0 rgba(76, 87, 125, 0.02);
  .card-header {
    border-color: #f1f1f3;
    padding: 12px 20px;
  }
  .card-title {
    font-weight: 600;
    font-size: 16px;
    color: $dark;
    margin-bottom: 0px;
  }
  .card-body {
    padding: 15px 20px;
  }
}

// link css
a,
.a {
  color: $theme-color;
  // font-weight: 600;

  transition: all 0.3s ease;
  &:hover {
    color: $theme-dark-color;
  }
}

.text-gray {
  color: $gray;
}
.text-error {
  font-size: 11px;
  color: $danger;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-decoration-underline {
  text-decoration: underline;
}
.text-underline-hover:hover {
  text-decoration: underline;
}
.text-link {
  color: inherit;
  &:hover {
    color: $primary;
  }
}

.feather-icon {
  width: 18px;
  height: 18px;
}

//button css
.btn {
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px 20px;
  box-shadow: none;
  transition: all 0.3s ease;
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &.btn-sm {
    padding: 4px 10px;
    .feather-icon {
      width: 14px;
      height: 14px;
    }
  }
  &.btn-light {
    color: $gray;
    border-color: #f5f8fa;
    background-color: #f5f8fa;
    &:hover {
      color: $primary;
      border-color: #f1faff;
      background-color: #f1faff;
    }
  }
  &.btn-light-primary {
    color: $primary;
    border-color: #f5f8fa;
    background-color: #f5f8fa;
    &:hover {
      color: $white;
      border-color: $primary;
      background-color: $primary;
    }
  }
}

//status-badge
.badge-count {
  background: #ddd;
  width: auto;
  height: auto;
  padding: 2px 4px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 11px;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $success;
  color: $white;
  border-radius: 5px;
  padding: 5px 12px;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  min-width: 70px;
  transition: all 0.25s ease;
  &.status-active {
    color: $success;
    background-color: lighten($color: $success, $amount: 45);
  }
  &.status-inactive {
    color: $danger;
    background-color: rgbA(228, 63, 82, 0.25);
  }
  &.status-pending {
    color: $warning;
    background-color: lighten($color: $warning, $amount: 45);
  }
  &.status-info {
    background-color: lighten($color: $info, $amount: 45);
    color: $info;
  }
  &.status-open {
    background-color: lighten($color: $primary, $amount: 45);
    color: $primary;
  }
  &.status-secondary {
    background-color: rgba(13, 14, 14, 0.12);
    color: $white;
  }
  &.status-primary {
    background-color: lighten($color: $success, $amount: 45);
    color: $success;
  }
}

// table css
.table.custom-table {
  td,
  th {
    padding: 12px 20px;
    vertical-align: middle;
    border-top: 1px solid #eff2f7;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #505d69;
  }
  th {
    background-color: #f8f9fa;
    border-bottom-width: 1px;
    font-weight: 700;
    color: $dark;
    border-color: #eff2f7;
  }
  tr {
    &:hover {
      color: $dark;
      background-color: #f5f5f5;
    }
    .name-title {
      font-size: 14px;
      font-weight: 700;
      color: $dark;
    }
  }
  .action-wrap {
    @include center-item;
    .action-btn {
      @include center-item;
      color: $black;
      border-radius: 5px;
      font-size: 18px;
      margin: 0px 8px;
      cursor: pointer;
      transition: all 0.25s ease;
      &:hover {
        color: $theme-color;
      }
      .feather-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.loader-div {
  position: relative;
  min-height: 150px;
  width: 100%;
  @include center-item;
}

.modal {
  .modal-header {
    color: $dark;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    .close-icon {
      &:hover {
        color: $primary;
      }
    }
  }
}

// drawer
.common-drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1030;
  right: 0px;
  width: 0;
  height: 100%;

  .drawer-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 0;
    transition: opacity 0.3s linear, height 0s ease 0.3s;
    pointer-events: none;
  }
  &.show {
    width: 100%;
    .drawer-mask {
      height: 100%;
      opacity: 1;
      transition: none;
      pointer-events: auto;
    }
    .drawer-content-wrapper {
      transform: translateX(0);
    }
  }
  .drawer-content-wrapper {
    width: 360px;
    background: #fff;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
      box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    box-shadow: -6px 0 16px -8px rgb(0 0 0 / 8%), -9px 0 28px 0 rgb(0 0 0 / 5%),
      -12px 0 48px 16px rgb(0 0 0 / 3%);
    transform: translateX(100%);

    .drawer-content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      overflow: auto;
      background-color: #fff;
    }
    .drawer-header {
      position: relative;
      padding: 12px 20px;
      border-bottom: 1px solid #d6d6d6;
      border-radius: 2px 2px 0 0;
      color: rgba(0, 0, 0, 0.85);
      background: #fff;
      .drawer-title {
        font-size: 20px;
        font-weight: 600;
        color: #000;
        line-height: 22px;
      }
      .drawer-close {
        position: absolute;
        top: 0;
        right: 8px;
        z-index: 10;
        color: $dark;
        font-weight: 700;
        cursor: pointer;
        transition: color 0.3s;
        height: 100%;
        &:hover {
          color: $theme-color;
          text-decoration: none;
        }
      }
    }
    .drawer-body {
      flex-grow: 1;
      padding: 10x;
      overflow: auto;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
    }
    .drawer-footer {
      position: relative;
      padding: 12px 20px;
      border-top: 1px solid #d6d6d6;
      border-radius: 2px 2px 0 0;
      color: rgba(0, 0, 0, 0.85);
      background: #fff;
    }
  }
}

//pagination

.pagination {
  .page-item {
    .page-link {
      cursor: pointer;
    }
  }
}

// nodata start
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin: 30px 0px;
  position: relative;
  min-height: 100px;
  .no-data-icon {
    font-size: 30px;
  }
  .no-data-content {
    h5 {
      color: $dark;
    }
  }
}
// nodata end

// sweetalert start
.swal2-container {
  font-family: "Montserrat", sans-serif;
  .swal2-title {
    font-size: 18px;
    font-weight: 700;
    color: $dark;
  }
  .swal2-content,
  .swal2-html-container {
    color: $dark;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
  }
  button.swal2-confirm {
    background: $primary !important;
    border: 1px solid $primary !important;
    box-shadow: none !important;
    color: $white;
    padding: 8px 20px;
    min-width: 90px;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    transition: all 0.25s ease;
    border-radius: 4px;
    box-shadow: none;
  }
  button.swal2-cancel {
    margin-left: 10px;
    color: $white;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    background-color: $secondary !important;
    border: 1px solid $secondary !important;
    box-shadow: none !important;
    padding: 8px 20px;
    min-width: 90px;
    box-shadow: none;
  }
  .swal2-popup {
    max-width: 350px;
    padding: 20px 0px;
  }
  .swal2-icon {
    margin-top: 5px;
    margin-bottom: 12px;
    width: 3em;
    height: 3em;
    &::before {
      font-size: 2.5em;
    }
    .swal2-icon-content {
      font-size: 2.5em;
    }
  }
  .swal2-actions {
    margin-top: 15px;
  }
}
// sweetalert end

.time-widget {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  .time-status {
    text-transform: capitalize;
  }
}
.badge {
  line-height: inherit;
}
.filter-widget {
  max-width: 680px;
  .form-control {
    height: 46px;
    border-color: #ced4da;
    font-size: 15px;
  }
  .btn:not(:hover) {
    background-color: #fff;
  }
}
.dashhead-subtitle {
  color: #adb5bd;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 5px;
  margin-top: 0;
  text-transform: uppercase;
  font-size: 15px;
}
.page-title {
  font-size: 26px;
  line-height: 30px;
  color: #343a40;
  font-weight: 600;
  .title-icon {
    color: $primary;
  }
}

// datatable css

.data-table-extensions {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 30px !important;
  position: absolute;
  top: 15px;
  right: 76px;
  width: auto !important;
}
.data-table-extensions-filter {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  padding: 5px 4px 5px 25px;
  color: #181c32;
  height: auto;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  transition: color 0.2s ease, background-color 0.2s ease;
  margin-right: 10px;
  position: relative;
  .icon {
    position: absolute;
    left: 7px;
    top: 0px;
    height: 100% !important;
    float: none !important;
  }
  .filter-text {
    border: 0px !important;
  }
}
.data-table-extensions-action {
  button {
    border: 1px solid $primary;
    margin-right: 8px !important;
  }
  .print {
    display: none !important;
  }
}

.rdt_Table {
  font-family: "Montserrat", sans-serif;
  .rdt_TableHead {
    font-size: 14px;
  }
  .rdt_TableHeadRow {
    .heiDwS {
      display: none;
    }
  }
  .rdt_TableRow .iEmSDR {
    display: none;
  }
  .rdt_TableRow,
  .rdt_TableHeadRow {
    .jLNFdK,
    .hwQcUz {
      display: none;
    }
  }
  .rdt_TableCell {
    padding: 12px 20px;
    vertical-align: middle;
    border-top: 1px solid #eff2f7;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #505d69;
  }
  .rdt_TableCol {
    background-color: #f8f9fa;
    border-bottom-width: 1px;
    font-weight: 700;
    font-size: 14px;
    color: $dark;
    border-color: #eff2f7;
  }
}
.table.custom-table .action-wrap {
  display: flex !important;
  justify-content: flex-start !important;
}

.common-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
  .filter-col,
  .content-col {
    position: relative;
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
  }
  .filter-col {
    flex: 0 0 300px;
    max-width: 300px;
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .content-col {
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.aside-filter {
  h6 {
    font-weight: 700;
  }
  .card-body {
    height: calc(100vh - 250px);
    overflow-y: auto;
    padding: 12px;
    @include custom-scroll;
  }
}
