// Variable overrides
$font-family-nunito: "Nunito", sans-serif;
$black: #000;
$transparent: transparent;
$white: #fff;

$primary: #4397d3;
$secondary: #303d53;
$success: #28c76f;
$info: #3699ff;
$warning: #ffa800;
$danger: #e43f52;
$light: #f5f8fa;
$dark: #181c32;
$gray: #7e8299;

$theme-color: #4397d3;
$theme-dark-color: #2f7db4;
