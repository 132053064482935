// form css
.form-group {
  .form-control {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    padding: 12px 16px;
    color: $dark;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    transition: color 0.2s ease, background-color 0.2s ease;
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: $dark;
    }
    &.input-sm {
      padding: 8px 12px;
    }
  }

  .input-icon {
    position: absolute;
    height: 100%;
    top: 0px;
    right: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    color: $secondary;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.custom-label {
  color: $dark;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
}

.phonenumber-wrapper {
  .country-select {
    flex: 0 0 85px;
    max-width: 85px;
    margin-right: 10px;
  }
  .input-div {
    flex: 1;
  }
}

// checkbox
.custom-checkbox {
  padding-left: 30px;
  .custom-control-label {
    font-weight: 600;
    color: $secondary;
    font-size: 14px;
    cursor: pointer;
    padding-left: 8px;
    &::before {
      width: 22px;
      height: 22px;
      border: #d0d2d4 solid 2px;
      border-radius: 2px;
      left: -30px;
      top: 0px;
    }
    &::after {
      width: 24px;
      height: 26px;
      left: -31px;
      top: -1px;
    }
  }
  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        &::before {
          color: $white;
        }
        &:after {
          // background-image: url("../assets/images/icons/check.svg");
        }
      }
    }
    &:focus {
      & ~ .custom-control-label {
        &::before {
          box-shadow: none;
          // border-color: $theme-primary-color !important;
        }
      }
    }

    &:not(:disabled):active {
      & ~ .custom-control-label {
        &::before {
          color: $white;
        }
      }
    }
  }
}

.custom-radio-input {
  .custom-control-label {
    cursor: pointer;
    font-weight: 600;
    &::before {
      border: #f5f8fa solid 1px;
    }
  }
  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        &::before {
          color: $white;
          border-color: $theme-color;
          background-color: $theme-color;
        }
      }
    }
    &:focus {
      & ~ .custom-control-label {
        &::before {
          box-shadow: none;
          border-color: #f5f8fa !important;
        }
      }
    }

    &:not(:disabled):active {
      & ~ .custom-control-label {
        &::before {
          color: $white;
          border-color: $theme-color;
          background-color: $theme-color;
        }
      }
    }
  }
}

// react select
.custom-reactselect {
  font-family: "Montserrat", sans-serif;
  .custom-inner-reactselect__placeholder {
    font-size: 14px;
    color: #a1a9b5;
    margin: 0px;
  }
  .custom-inner-reactselect__single-value {
    color: $dark;
    margin: 0px;
  }
  .custom-inner-reactselect__value-container {
    padding: 0px 5px 0px 0px;
  }
  .custom-inner-reactselect__control {
    min-height: 47px;
    padding-left: 12px;
    border-radius: 6px;
    vertical-align: middle;
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: $dark;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    transition-duration: 0.3s;
    box-shadow: none;
    &.custom-inner-reactselect__control--is-focused {
      border-color: $dark;
    }
    &.custom-inner-reactselect__control--is-disabled {
      background-color: #ddd;
      opacity: 0.7;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      box-shadow: none;
      border-color: #f5f8fa;
    }
  }
  .custom-inner-reactselect__indicator {
    padding: 4px;
    color: #a5a5a5;
  }
  .custom-inner-reactselect__option {
    color: $dark;
  }
  .custom-inner-reactselect__menu {
    z-index: 2;
    color: $dark;
    .custom-inner-reactselect__menu-notice--no-options {
      color: $dark;
    }
  }
}

// date picker
.custom-datepicker {
  position: relative;
  .react-datepicker-wrapper {
    display: block;
    font-family: "Montserrat", sans-serif;
  }
  .input-icon {
    position: absolute;
    top: 0px;
    color: #afacac;
    font-size: 15px;
    right: 5px;
  }
  .custom-input {
    background-color: #f5f8fa;
    border: 1px solid #f5f8fa;
    padding: 12px 16px;
    color: $dark;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    width: 100%;
    transition: color 0.2s ease, background-color 0.2s ease;
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: $dark;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    .react-datepicker__triangle {
      border-bottom-color: $primary !important;
      &::before {
        top: 1px;
        border-bottom-color: $primary !important;
      }
    }
  }
  .react-datepicker-popper[data-placement^="top"] {
    .react-datepicker__triangle {
      &::before {
        border-top-color: $white !important;
      }
    }
  }

  .react-datepicker {
    font-family: "Montserrat", sans-serif;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    font-size: 12px;
    border: 0px;
    .react-datepicker__navigation--previous {
      border-right-color: $white;
    }
    .react-datepicker__navigation--next {
      border-left-color: $white;
    }

    .react-datepicker__header {
      background-color: $primary;
      border-color: $primary;
      .react-datepicker__header__dropdown {
        margin-top: 4px;
        select {
          background: #fff;
          border: 0px;
          border-radius: 4px;
          padding: 2px 6px;
        }
      }
    }
    .react-datepicker__current-month {
      color: $white;
      font-weight: 500;
    }
    .react-datepicker__day--keyboard-selected {
      border-radius: 50%;
      background-color: $primary;
      color: $white;
    }
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: $white;
      }
    }
    .react-datepicker__day {
      &:hover {
        border-radius: 50%;
        background-color: #d0d0d0;
      }
      &.react-datepicker__day--disabled {
        &:hover {
          background-color: $transparent;
        }
      }
    }
  }
}

.filter-field-wrap {
  display: flex;
  align-items: flex-start;

  .filter-field-tile {
    max-width: 240px;
    flex: 0 0 240px;
    padding: 0px 4px;
    // &.status-tile {
    //   flex: 0 0 240px;
    //   max-width: 240px;
    // }
    &.buttons-tile {
      flex: 0 0 auto;
      max-width: auto;
    }
    .form-group {
      margin-bottom: 0px;
      min-height: 40px;
    }
    label {
      display: none;
    }
    .custom-reactselect {
      .custom-inner-reactselect__control {
        min-height: 40px;
      }
    }
  }
  .filter-btn {
    height: 40px;
    width: 40px;
    min-width: auto;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0px;
    font-size: 16px;
    line-height: normal;
    transition: all 0.3s ease;

    &.search-btn {
      color: $white;
      background-color: $primary;
      border-color: $primary;
      &:hover {
        box-shadow: -1px 3px 12px 0px $primary;
      }
    }
    &.reset-btn {
      margin-left: 10px;
      color: $white;
      background-color: $gray;
      border-color: $gray;
      &:hover {
        box-shadow: -1px 3px 12px 0px $gray;
      }
    }
  }
}

.filter-dropdown {
  .btn {
    min-height: 40px;
    min-width: 40px;
    border-radius: 2px;
    padding: 8px 10px;
  }
  .dropdown-menu {
    min-width: 300px;
    border-radius: 0.475rem;
    background-color: #fff;
    box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
    border: 0px;
    max-height: 400px;
    overflow-y: auto;
    .dropdown-item {
      border-top: 1px solid #efefef;
      &.filter-search {
        text-decoration: none;
        color: $dark;
        background-color: $transparent;
      }
    }
  }
}
